<template>
    <div>
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true,right:'实名认证',real:true}"></v-header>
        </div>
        <div class="earn">
            <div class="total">
                <div class="middle">
                    <p>总收益<img @click="goPage('help')" src="../../assets/images/help.png" alt=""></p>
                    <p class="num">0.00</p>
                </div>
                <div class="right" @click="goPage('record')">
                    <p>兑换记录<img src="../../assets/images/right_arrow.png" alt=""></p>
                </div>
            </div>
        </div>
        <div class="exchange">
            <div class="item">
                <p>兑换审批中<img @click="goPage('help')" src="../../assets/images/help.png" alt=""></p>
                <p class="num">0.00</p>
            </div>
            <div class="item">
                <p>已累计兑换<img @click="goPage('help')" src="../../assets/images/help.png" alt=""></p>
                <p class="num">0.00</p>
            </div>
        </div>
        <div class="exchangeBtn">
            <button>兑换收益</button>
        </div>
        <div class="proxy">
            <p>
                <input type="checkbox" name="" id="">
                <span>我已阅读并同意以下协议和规范</span>
            </p>
            <p @click="goPage('exchangeProxy')">《兑换服务协议》</p>
            <p @click="goPage('right')">《大师权利与义务》</p>
            <p @click="goPage('manage')">《彩友圈管理规范》</p>
            <p @click="goPage('payProxy')">《充值服务协议》</p>
            <p @click="goPage('appreciate')">《增值服务协议》</p>
            <p @click="goPage('privacy')">《隐私协议》</p>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .earn{
        width: 94%;
        height: 60px;
        margin: 10px auto;
        .total{
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            .middle{
                flex: 1;
                font-size: 14px;
                color: rgb(135, 130, 130);
                text-align: center;
                .num{
                    color: #000;
                    font-size: 22px;
                }
                p{
                    padding: 0;
                    margin: 0;
                    height: 30px;
                    img{
                        margin-left: 4px;
                        width: 12px;
                        height: 12px;
                    }
                }
            }
            .right{
                position: absolute;
                p{
                    font-size: 16px;
                    img{
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
    }
    .exchange{
        width: 80%;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        .item{
            p{
                font-size: 14px;
                color: rgb(135, 130, 130);
                text-align: right;
                img{
                    margin-left: 4px;
                    width: 12px;
                    height: 12px;
                }
            }
            .num{
                color: #000;
                font-size: 22px;
            }
        }
    }
    .exchangeBtn{
        width: 100%;
        height: 40px;
        text-align: center;
        button{
            width: 220px;
            height: 40px;
            background-color: #d25555;
            color: #fff;
            border: none;
            border-radius: 6px;
            font-size: 14px;
        }
    }
    .proxy{
        padding: 10px;
        p{
            font-size: 12px;
            width: 120px;
            &:first-child{
                width: 200px;
                input{
                    vertical-align: middle;
                    width: 14px;
                    height: 14px;
                }
                span{
                    vertical-align: middle;
                }
            }
            &:not(&:first-child){
                color: #d25555;
            }
        }
        
    }
</style>